<div class="dialog">
  <div class="modal-header">
    <img class="modal-close" *ngIf="canClose" (click)="close()" alt="" />
  </div>
  <div class="modal-body">
    <div *ngIf="!isEngineCheck">
      <img class="modal-body__image mt-4" src="../../assets/images/thumbsUp.png" alt="" />
      <div class="modal-body__message mt-4 mb-4">
        <span *ngIf="hasNewRequests">That request has been received.</span>
        <span *ngIf="!hasNewRequests">Authorisation has already been requested.</span>
        <br>
        Below is the authorisation decision status:
      </div>
      <div class="requests" [ngClass]="enableRetakeButton ? 'margin-auto' : ''">
        <ng-container *ngFor="let auth of authorizationRequests; let i = index;">
          <div class="row" >
            <div *ngIf="!enableRetakeButton" class="dynamic-width">
              {{ getAuthorizationTypeDescription(auth.authorizationTypeId) }}:
            </div>
            <div class="fixed-width status" [ngClass]="getStatusClass(auth)">
              {{ getStatus(auth) }}
            </div>
          </div>
          <div class="mt-4 mb-4" *ngIf="hasComments">
            <div class="text-center comments col-12" >Comments:</div>
            <div class="col-12 text-center declined-reason">{{auth.declinedReason}}</div>
          </div>
        </ng-container>
      </div>
      
      <div class="row" [ngClass]="lastRefresh ? 'no-bottom-margin' : ''">
        <div class="dynamic-width">
          To refresh status:
        </div>
        <div class="fixed-width">
          <div class="refresh">
            <button class="modal-body__button refresh_button" [disabled]="canRefresh ? null : true"
              (click)="refresh()">Refresh</button>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="lastRefresh">
        <div class="dynamic-width">
        </div>
        <div class="fixed-width">
          <div class="refresh">
            <div class="last-refresh">Refreshed at {{ lastRefresh | date:"HH:mm:ss dd/MM/yy " }}</div>
          </div>
        </div>
      </div>
      <div class="d-flex" style="justify-content: space-between;">
        <div *ngIf="canClose" class="col-6" [ngClass]="{'col-12': !enableRetakeButton}">
          <button class="modal-body__button mt-4 button_green" [ngClass]="{'big-button': enableRetakeButton}" (click)="close()">Ok</button>
        </div>
        <div *ngIf="enableRetakeButton && canClose" class="col-6">
          <button class="modal-body__button btn btn-primary mt-4 big-button" (click)="retakeVideo()">Retake Video</button>
        </div>
      </div>
    </div>
  </div>
</div>