import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventsService } from '../../services/events.service';
import { AuthTask } from '../../task-list/models/auth-task.model';

@Component({
  selector: 'app-decline-reason',
  templateUrl: './decline-reason.component.html',
  styleUrl: './decline-reason.component.scss'
})
export class DeclineReasonComponent implements OnInit, AfterViewInit {

  @ViewChild('textArea') textArea;
  @Input() authTask: AuthTask;
  text:string;
  textAreaElement;

  constructor(
    private activeModal: NgbActiveModal,
    private eventsService: EventsService
  ) {
  }

  ngOnInit() {
    this.eventsService.closeModal.subscribe(() => {
      this.close();
    });
  }

  ngAfterViewInit() {
    this.textAreaElement = this.textArea.nativeElement;
  }

  clear(){
    this.textAreaElement.value = "";
  }

  declineTask(){
    this.authTask.declinedReason = this.textAreaElement.value;
    this.eventsService.declineTask.emit(this.authTask);
  }

  close(): void {
    this.activeModal.close();
  }
}
