<div class="modal-body obfuscated-playback video-box" >
    <video #videoPlayer width="100%" height="100%" playsinline="false" controls controlslist="nodownload" disablepictureinpicture="true" (click)="openVideo(videoPreview)">
    </video>
</div>


<ng-template #videoPreview let-modal>
    <div class="modal-header">
      <h4 class="modal-title text-center">{{ previewStamp }}</h4>
    </div>
    <div class="modal-body">
      <video [src]="videoUrl" width="100%" playsinline="false" controls controlslist="nodownload"
        disablepictureinpicture="true"></video>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary" (click)="modal.close()" ngbAutofocus>Close</button>
    </div>
  </ng-template>

  
<ng-container *ngIf="loading">
  <app-loading-wbac-animation></app-loading-wbac-animation>
</ng-container>