import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthorizationRequest } from '../models/authorization-request.model';
import { AuthorizationRequestService } from '../../services/authorization-request.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { EventsService } from '../../services/events.service';
import { PathFinderService } from '../../services/path-finder.service';
import { AuthService } from '../../core/auth-service';
import { AuthorizationTypes } from '../enums/authorization-types';

@Component({
  selector: 'app-engine-check',
  templateUrl: './engine-check.component.html',
  styleUrl: './engine-check.component.scss'
})
export class EngineCheckComponent implements OnInit {
  @Output() videoChanged = new EventEmitter<boolean>();
  @Input() isNew = false;
  @Input() isRetake = false;

  canClose = true;
  videoTab = false;
  isVideoLoaded = false;
  isSendEnabled = false;
  showProgressBar = false;
  showCompletedMessage = false;
  loading: boolean;
  uploadSucceded: boolean;
  quoteStateId: number;
  attemptsExceeded: boolean;
  currentUploadProgress: number;
  authorizationRequests = new Array<AuthorizationRequest>();


  group = new UntypedFormGroup({
    value: new UntypedFormControl('', Validators.required),
  });

  constructor(
    private activeModal: NgbActiveModal,
    private authorizationRequestService: AuthorizationRequestService,
    private eventsService: EventsService,
    private authService: AuthService,
    private modalService: NgbModal,
    private pathFinder: PathFinderService
  ) {
  }

  ngOnInit() {
    if (!this.group) {
      this.group = new UntypedFormGroup({
        value: new UntypedFormControl('', Validators.required),
      });
    }
    this.eventsService.videoChanged.subscribe((response: boolean) => {
      this.isSendEnabled = response;
    });
    this.eventsService.uploadSucceded.subscribe((response: boolean) => {
      this.loading = false;
      this.uploadSucceded = response;
    });
    this.eventsService.attemptsExceeded.subscribe(() => {
      this.attemptsExceeded = true;
    });
    this.eventsService.currentUploadProgress.subscribe((response: number) => {
      if (response !== 0) {
        this.showProgressBar = true;
      }
      if (response === 100) {
        this.showCompletedMessage = true;
      }
      this.currentUploadProgress = response;
    });
  }

  loadVideoTab() {
    this.videoTab = true;
  }

  async createAuthRequest() {
    this.authorizationRequests = new Array<AuthorizationRequest>();
    const username = this.authService.getUser()?.username.replace('@webuyanycar.com', '').toLowerCase();
    const authorizationRequest = new AuthorizationRequest(this.quoteStateId, AuthorizationTypes.AdditionalEngineChecks, null, username);
    if(this.isRetake){
      authorizationRequest.retake = true;
    }
    this.authorizationRequests.push(authorizationRequest);
  }

  async sendVideo(uploadSucceded: boolean, attemptsExceeded: boolean) {
    if (uploadSucceded || attemptsExceeded) {
      if (this.isNew) {
        await this.createAuthRequest();
      }
      this.requestModal();
    } else {
      this.loading = true;
      this.eventsService.videoSent.emit();
    }
  }

  requestModal() {
    this.activeModal.close(this.authorizationRequests);
    if (!this.isNew) {
      this.authorizationRequestService.processRequestModalAsync(this.authorizationRequests, false, false, false)
    }
  }

  close(): void {
    this.pathFinder.endTransitionEvent();
    this.eventsService.closeComponent.emit();
    this.activeModal.close(this.authorizationRequests);
  }
}
