import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthorizationRequestComponent } from '../authorization/authorization-request.component';
import { AuthorizationTypes } from '../authorization/enums/authorization-types';
import { AuthorizationRequest } from '../authorization/models/authorization-request.model';
import { ConfirmDialogService } from '../confirm-dialog/services/confirm-dialog.service';
import { AuthService } from '../core/auth-service';
import { AuthorizationRequired } from '../user-input/models/authorization-required.model';
import { NotificationService } from './notification.service';
import { AuthTask } from '../task-list/models/auth-task.model';
import { EngineCheckComponent } from '../authorization/engine-check/engine-check.component';

@Injectable({
  providedIn: 'root',
})

export class AuthorizationRequestService {

  static getAuthorizationTypeDescription(authorizationType: AuthorizationTypes) {
    switch (authorizationType) {
      case AuthorizationTypes.HpiMismatch: return 'HPI Mismatch';
      case AuthorizationTypes.FlexBand1: return 'Flex Band 1';
      case AuthorizationTypes.FlexBand2: return 'Flex Band 2';
      case AuthorizationTypes.SuperFlex: return 'Super Flex';
      case AuthorizationTypes.D2Authorised: return 'D2 Authorised';
      case AuthorizationTypes.DvsaMileageDiscrepancyOverride: return 'DVSA Mileage Discrepancy Override';
      case AuthorizationTypes.DvsaClericalErrorOverride: return 'DVSA Clerical Error Override';
      case AuthorizationTypes.HistoryClearOverride: return 'History Clear Override';
      case AuthorizationTypes.PreviousOwnerOverride: return 'Number of Previous Keepers Override';
      case AuthorizationTypes.RecentKeeperChange: return 'Recent Keeper Change';
      case AuthorizationTypes.TheftMarkerOverride: return 'HPI Warning Override';
      case AuthorizationTypes.VehicleConditionVerification: return 'Vehicle Condition Verification';
      case AuthorizationTypes.ImportStatusOverride: return 'Import Status Override';
      case AuthorizationTypes.AdditionalVehicleCheckAuthorised: return 'Additional Vehicle Check';
      case AuthorizationTypes.AdditionalEngineChecks: return 'Additional Engine Checks';
      case AuthorizationTypes.V5QualityCheck: return 'V5 Quality Check';
      case AuthorizationTypes.InsuranceWriteOffOverride: return 'Insurance Write Off Override';
      case AuthorizationTypes.VehicleDetailsChanged: return 'Vehicle Details Changed';
      case AuthorizationTypes.ChangeVRM: return 'Change VRM';
      case AuthorizationTypes.OptionalExtra: return 'Optional Extra';
      case AuthorizationTypes.AltVRM: return 'Alt VRM';
      case AuthorizationTypes.ChangeOfVehicle: return 'Change of vehicle';
      default: return 'Unknown';
    }
  }

  constructor(
    private http: HttpClient,
    private modalService: NgbModal,
    private confirmDialogService: ConfirmDialogService,
    private authService: AuthService,
    private notifications: NotificationService
  ) {
  }

  getRequest$(requestId: number): Observable<AuthorizationRequest> {
    return this.http.get<AuthorizationRequest>(`${environment.ppxPrivateApi}api/authorizationrequest/${requestId}`).pipe(
      catchError(err => throwError(err))
    );
  }

  getRequests$(requestIds: number[]): Observable<Array<AuthorizationRequest>> {
    const query = requestIds.map(r => `id=${r}`).join('&');
    return this.http.get<Array<AuthorizationRequest>>(`${environment.ppxPrivateApi}api/authorizationrequest?${query}`).pipe(
      catchError(err => throwError(err))
    );
  }

  submitRequest$(request: AuthorizationRequest): Observable<AuthorizationRequest> {
    return this.http.post<AuthorizationRequest>(`${environment.ppxPrivateApi}api/authorizationrequest`, request).pipe(
      catchError(err => throwError(err))
    );
  }

  getRequestByQuoteStateId$(quoteStateId: number): Observable<Array<AuthorizationRequest>> {
    return this.http.get<Array<AuthorizationRequest>>(`${environment.ppxPrivateApi}api/AuthorizationRequest/getauthorizationrequests/${quoteStateId}`).pipe(
      catchError(err => throwError(err))
    );
  }

  async confirmRequestsAsync(authsRequired: Array<AuthorizationRequired>): Promise<boolean> {
    const description = authsRequired.map(a => AuthorizationRequestService.getAuthorizationTypeDescription(a.authorizationTypeId)).join(', ');
    return await this.confirmDialogService.confirmAuthorizationRequestAsync(description);
  }

  async processRequestsAsync(quoteStateId: number, authsRequired: Array<AuthorizationRequired>,
    existingRequests: Array<AuthorizationRequest>, alreadyConfirmed: boolean) {

    const authRequests = new Array<AuthorizationRequest>();
    let newRequests = new Array<AuthorizationRequest>();
    let checkRequests = new Array<AuthorizationRequest>();
    let retake = false;

    try {
      // Check if we already have any auth requests
      for (const authRequired of authsRequired) {
        const existingRequest = this.findExistingAuthorizationRequest(authRequired, existingRequests);
        if (existingRequest) {
          authRequests.push(existingRequest);
        }
        else if (authRequired.authorizationTypeId === AuthorizationTypes.AdditionalEngineChecks) {
          const description = AuthorizationRequestService.getAuthorizationTypeDescription(authRequired.authorizationTypeId);
          const confirm = await this.confirmDialogService.confirmAuthorizationRequestAsync(description);
          if (confirm) {
            newRequests = await this.processRequestModalEngineCheckAsync(quoteStateId);
            alreadyConfirmed = true;
          }
        }
        else {
          const username = this.authService.getUser()?.username.replace('@webuyanycar.com', '').toLowerCase();
          const authorizationRequest = new AuthorizationRequest(quoteStateId, authRequired.authorizationTypeId, authRequired.value, username);
          newRequests.push(authorizationRequest);
        }
      }

      if (newRequests.length > 0) {
        const description = newRequests.map(a => AuthorizationRequestService.getAuthorizationTypeDescription(a.authorizationTypeId)).join(', ');
        let confirm = alreadyConfirmed;
        if (!alreadyConfirmed) {
          confirm = await this.confirmDialogService.confirmAuthorizationRequestAsync(description);
        }
        if (confirm) {
          for (const authRequest of newRequests) {
            const newRequest = await this.submitRequest$(authRequest).toPromise();
            newRequest.isNew = true;
            authRequests.push(newRequest);
            existingRequests.push(newRequest);
          }
        } else {
          return new Array<AuthorizationRequest>();
        }
      }

      if (authRequests.some(a => (!a.approved && !a.declined))) {
        await this.processRequestModalAsync(authRequests, newRequests.length > 0, false, false);
      } else if (authRequests.some(a => a.declined)) {
        checkRequests = await this.processRequestModalAsync(authRequests, newRequests.length > 0, false, false);
      }

      retake = checkRequests.some(a => a.retake === true);
      while (retake) {
        let requests = new Array<AuthorizationRequest>();
        newRequests = await this.processRequestModalEngineCheckAsync(quoteStateId);
        if (newRequests.length > 0) {
          for (const authRequest of newRequests) {
            const newRequest = await this.submitRequest$(authRequest).toPromise();
            newRequest.isNew = true;
            authRequests[0] = newRequest;
            existingRequests.push(newRequest);
          }
        }

        requests = await this.processRequestModalAsync(authRequests, newRequests.length > 0, false, false);
        retake = requests.some(a => a.retake === true);
      }

    } catch (error) {
      this.notifications.dangerToast('Unable to process the authorization request', error);
    }

    return authRequests;
  }

  private findExistingAuthorizationRequest(authRequired: AuthorizationRequired, existingRequests: Array<AuthorizationRequest>): AuthorizationRequest {
    let authorizationRequest;
    if (authRequired.valueParameter) {
      authorizationRequest = existingRequests.find(a => a.authorizationTypeId === authRequired.authorizationTypeId && a.value === authRequired.value);
    } else {
      authorizationRequest = existingRequests.find(a => a.authorizationTypeId === authRequired.authorizationTypeId);
    }

    return authorizationRequest;
  }

  public async processRequestModalEngineCheckAsync(quoteStateId: number) {
    // Engine Check Route
    const engineCheckModal = this.modalService.open(EngineCheckComponent, {
      keyboard: false,
      backdrop: 'static',
      centered: true,
      size: 'md',
    });

    engineCheckModal.componentInstance.quoteStateId = quoteStateId;
    engineCheckModal.componentInstance.isNew = true;

    return engineCheckModal.result;
  }

  public async processRequestModalAsync(requests: Array<AuthorizationRequest>, hasNew: boolean, isEngineCheck: boolean, isNew: boolean) {
    if (!isEngineCheck) {
      // Default Request Route
      const requestModal = this.modalService.open(AuthorizationRequestComponent, {
        keyboard: false,
        backdrop: 'static',
        centered: true
      });

      requestModal.componentInstance.authorizationRequests = requests;
      requestModal.componentInstance.hasNewRequests = hasNew;

      return requestModal.result;
    } else {
      // Engine Check Route
      const engineCheckModal = this.modalService.open(EngineCheckComponent, {
        keyboard: false,
        backdrop: 'static',
        centered: true,
        size: 'md',
      });

      engineCheckModal.componentInstance.isNew = isNew;
      engineCheckModal.componentInstance.authorizationRequests = requests;
      engineCheckModal.componentInstance.quoteStateId = requests.filter(x => x.authorizationTypeId === 36).map(y => y.quoteStateId)[0];

      await engineCheckModal.result;
    }

  }

  updateAuthRequest$(request: AuthorizationRequest): Observable<AuthorizationRequest> {
    return this.http.post<AuthorizationRequest>(`${environment.ppxPrivateApi}api/authorizationrequest/UpdateAuthorizationRequest`, request).pipe(
      catchError(err => throwError(err))
    );
  }

  updateAuthRequestDeclinedValue$(request: AuthorizationRequest): Observable<AuthorizationRequest> {
    return this.http.post<AuthorizationRequest>(`${environment.ppxPrivateApi}api/authorizationrequest/UpdateDeclinedValue`, request).pipe(
      catchError(err => throwError(err))
    );
  }

  updateAuthTask(task: AuthTask) {
    const authRequest = new AuthorizationRequest(task.quoteStateId, task.authorizationTypeId, task.value, task.requestedBy);
    authRequest.authorizationRequestId = task.authRequestId;
    authRequest.approved = task.approved;
    authRequest.declined = task.declined;
    authRequest.claimed = task.claimed;
    authRequest.claimedBy = task.claimedBy;
    authRequest.requested = task.requested;
    authRequest.authorizedBy = task.authorizedBy;
    authRequest.value = task.value;
    authRequest.declinedReason = task.declinedReason;
    this.updateAuthRequest$(authRequest).pipe(
      catchError(err => {
        this.notifications.dangerToast(`Failed to update Authorisation Request`, err);
        return EMPTY;
      }),
      take(1)
    ).subscribe();
  }
}
