import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponentDirective } from '../base/base.component';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-congratulations',
  templateUrl: './congratulations.component.html',
  styleUrls: ['./congratulations.component.scss']
})
export class CongratulationsComponent extends BaseComponentDirective implements OnInit {

  @Input() quoteStateId: number;
  @Input() group: UntypedFormGroup;
  @Output() stateReturn = new EventEmitter<string>();

  loading: boolean;

  constructor() {
    super();
  }

  ngOnInit() {
    this.loading = false;
    this.group.controls.value.setValue(true);
  }
}
