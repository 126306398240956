import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private toastr: ToastrService, private loggingService: LoggingService) { }

  private options = {
    disableTimeOut: true,
    tapToDismiss: false,
    closeButton: true,
    positionClass: 'toast-bottom-right'
  };

  private successToastOptions = {
    timeOut: 5000, // success toasts have 5 second timeout
    tapToDismiss: false,
    closeButton: true,
    positionClass: 'toast-bottom-right'
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dangerToast(message: string, error: any) {
    let toastMessage = '';
    if (error && error.error) {
      if (error.error.Message) {
        toastMessage = `${message} - ${error.error.Message}`;
      } else {
        toastMessage = `${message} - ${error.error}`;
      }
    } else if (error && error.message) {
      toastMessage = `${message} - ${error.message}`;
    } else if (error) {
      toastMessage = `${message} - ${error}`;
    } else {
      toastMessage = message;
    }

    if (toastMessage.length > 400) {
      toastMessage = toastMessage.substring(0, 400);
    }
    this.toastr.error(toastMessage, 'Error', this.options);
    error.message = toastMessage;

    this.loggingService.logError(error);
  }

  warningToast(message: string) {
    this.toastr.warning(message, 'Warning', this.options);
  }

  successToast(message: string) {
    this.toastr.success(message, 'Success', this.successToastOptions);
  }

  clearToast() {
    this.toastr.clear();
  }
}
