<div class="dialog">
    <div class="modal-header">
        <img class="modal-close" *ngIf="canClose" (click)="close()" alt="" />
    </div>
    <div class="modal-body">
        <div *ngIf="!videoTab">
            <div class="title mb-4">
                <span>Engine Check Auth Steps</span>
                <br>
            </div>
            <div class="instructions">
                <div>
                    When starting the video please make sure the reg is in view, the engine is off and the bonnet is
                    open.
                    When taking the video please get as close as you can to the vehicle then start the engine.
                </div>
                <br>
                <div>
                    The steps are on the following page
                </div>
                <br>
                <div style="font-weight: 700;">
                    If any of the steps are missed the engine check authorisation will be declined.
                </div>
            </div>
            <br>
        </div>
        <div *ngIf="videoTab">
            <div class="title mb-4">
                <span>Video of Engine for Auth</span>
                <br>
            </div>
            <div class="d-flex row">
                <div class="instructions col-8" *ngIf="!attemptsExceeded">
                    <div>
                        1. Allow to idle for 5-10 seconds.
                    </div>
                    <br>
                    <div>
                        2. Take revs to 3000rpm for 5-10 seconds.
                    </div>
                    <br>
                    <div>
                        3. Allow engine to return to idle.
                    </div>
                    <br>
                    <div>
                        4. With the video still running, move from the engine bay into the cabin and clearly show the
                        dashboard, hold the image on the dashboard for 3 seconds.
                    </div>
                    <br>
                    <div>
                        5. Ask the customer to rev the vehicle to 3,000 revs for 5-10 seconds and video exhaust emissions.
                    </div>
                </div>
                <div class="instructions col-8" *ngIf="attemptsExceeded">
                    <div>
                        Video has failed to upload you will need to revert to the original Engine Check Auth process and take the video via WhatsApp.
                    </div>
                </div>
                <div class="video col-4">
                    <app-video-input [group]="group" [quoteStateId]="quoteStateId" [isEngineCheck]="true">
                    </app-video-input>
                </div>
            </div>
            <div class="progress progress-bar-margin" [hidden]="!showProgressBar || attemptsExceeded">
                <div class="progress-bar bg-success"  [style.width.%]="currentUploadProgress" role="progressbar"></div>
            </div>
            <div class="completed-message" *ngIf="showCompletedMessage">
                Upload Completed
            </div>
        </div>
    </div>
    <div class="footer">        
        <button type="button" *ngIf="!videoTab" class="button-next button_green" (click)="loadVideoTab()">
            Next
        </button>
        <button type="button" *ngIf="videoTab" class="button-next send_button button_green modal-button"
            [ngClass]="{'loading': loading, 'upload-succeded': uploadSucceded || attemptsExceeded}"
            [disabled]="!isSendEnabled" (click)="sendVideo(uploadSucceded, attemptsExceeded)">
            Send
        </button>
    </div>
</div>