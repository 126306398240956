<div class="dialog">

    <div class="modal-header">
        <h4 class="modal-title text-center">Declined Reason</h4>
        <img class="modal-close" (click)="close()" alt="" />
    </div>
    <div class="modal-body">
        <div class="row">
            <textarea #textArea class="col-12 row ms-0 me-0 text-area"
                placeholder="You can add a comment on why this auth request was declined:"></textarea>
            <div class="col-1"></div>
            <div class="col-5 ps-0 modal-button-left pad-top">
                <button class="btn btn-block btn-lg modal-button clear-button" (click)="clear()">
                    Clear
                </button>
            </div>
            <div class="col-5 modal-button-right pe-0 pad-top">
                <button class="btn btn-block btn-lg modal-button button_red" (click)="declineTask()">
                    Decline
                </button>
            </div>
            <div class="col-1"></div>
        </div>
    </div>

</div>