<div class="vehicle-detail-container" [ngClass]="{ 'center border wide': vehicleDetails }">
  <div *ngIf="hideDetails" class="row description-row">
    <div class="description header">VRM</div>
    <div class="description value" [ngClass]="altStyle === 1 ? 'blue' : altStyle === 2 ? 'purple' : altStyle === 3 ? 'green' : altStyle === 4 ? 'purple-large-text' : ''">{{ vrm |
      uppercase
      }}</div>
    <div class="w-100"></div>
  </div>
  <div *ngIf="!hideDetails" class="d-flex flex-row flex-wrap-mobile">
    <div *ngIf="!vehicleDetails" style="width: 100%">
      <div class="row description description-row">
        <div class="description header">Make</div>
        <div class="w-100"></div>
        <div class="description value" [ngClass]="altStyle === 1 ? 'blue' : altStyle === 2 ? 'purple' : altStyle === 3 ? 'green' : altStyle === 4 ? 'purple-large-text' : ''">
          {{ vehicleDescription.Manufacturer }}
        </div>
      </div>
      <div class="row description-row">
        <div class="description header">Model</div>
        <div class="w-100"></div>
        <div class="description value extended"
          [ngClass]="altStyle === 1 ? 'blue' : altStyle === 2 ? 'purple' : altStyle === 3 ? 'green' : altStyle === 4 ? 'purple-large-text' : ''">
          {{ modelFormat }}
        </div>
      </div>
    </div>
    <!-- vehicle details capId and make -->
    <div *ngIf="vehicleDetails" style="width: 100%">
      <div class="row description description-row">
        <div class="description header">CAP ID</div>
        <div class="w-100"></div>
        <div class="description value" [ngClass]="altStyle === 1 ? 'blue' : altStyle === 2 ? 'purple' : altStyle === 3 ? 'green' : altStyle === 4 ? 'purple-large-text' : ''">
          {{ capData }}
        </div>
        <div [ngClass]="{ 'description value addHeight': !vehicleDetails.capId}"></div>
      </div>
      <div class="row description-row">
        <div class="description header">Make</div>
        <div class="w-100"></div>
        <div class="description value"
          [ngClass]="altStyle === 1 ? 'blue' : altStyle === 2 ? 'purple' : altStyle === 3 ? 'green' : altStyle === 4 ? 'purple-large-text' : 'extended-vehicle-details-page'">
          {{ vehicleDetails.manufacturer }}
        </div>
        <div [ngClass]="{ 'description value addHeight': !vehicleDetails.capId}"></div>
      </div>
    </div>
    <!-- vehicle details capid and make -->
    <div class="vertical-line"></div>
    <div *ngIf="!vehicleDetails" style="width: 100%">
      <div class="row description description-row">
        <div class="description header">Colour</div>
        <div class="w-100"></div>
        <div class="description value" [ngClass]="altStyle === 1 ? 'blue' : altStyle === 2 ? 'purple' : altStyle === 3 ? 'green' : altStyle === 4 ? 'purple-large-text' : ''">{{
          vehicleDescription.Colour }}</div>
      </div>
      <div class="row description-row">
        <div class="description header">Engine</div>
        <div class="w-100"></div>
        <div class="description value extended"
          [ngClass]="altStyle === 1 ? 'blue' : altStyle === 2 ? 'purple' : altStyle === 3 ? 'green' : altStyle === 4 ? 'purple-large-text' : ''">
          {{ engineFormat }}
        </div>
      </div>
    </div>
    <!-- vehicle details colour and model -->
    <div *ngIf="vehicleDetails" style="width: 100%">
      <div class="row description-row">
        <div class="description header">Colour</div>
        <div class="w-100"></div>
        <div class="description value" [ngClass]="altStyle === 1 ? 'blue' : altStyle === 2 ? 'purple' : altStyle === 3 ? 'green' : altStyle === 4 ? 'purple-large-text' : ''">{{
          vehicleDetails.colour }}</div>
        <div [ngClass]="{ 'description value addHeight': !vehicleDetails.capId}"></div>
      </div>
      <div class="row description-row">
        <div class="description header">Model</div>
        <div class="w-100"></div>
        <div class="description value"
          [ngClass]="altStyle === 1 ? 'blue' : altStyle === 2 ? 'purple' : altStyle === 3 ? 'green' : altStyle === 4 ? 'purple-large-text' : 'extended-vehicle-details-page'">
          {{ modelFormat }}
        </div>
        <div [ngClass]="{ 'description value addHeight': !vehicleDetails.capId}"></div>
      </div>
    </div>
    <!-- vehicle details colour and model -->
    <div *ngIf="vehicleDetails" class="vertical-line"></div>
    <!-- vehicle details mileage and engine -->
    <div *ngIf="vehicleDetails" style="width: 100%">
      <div class="row description-row">
        <div class="description header">Mileage</div>
        <div class="w-100"></div>
        <div class="description value" [ngClass]="altStyle === 1 ? 'blue' : altStyle === 2 ? 'purple' : altStyle === 3 ? 'green' : altStyle === 4 ? 'purple-large-text' : ''">{{
          mileage |
          number: "1.0-0" }}</div>
        <div [ngClass]="{ 'description value addHeight': !vehicleDetails.capId}"></div>
      </div>
      <div class="row description-row">
        <div class="description header">Engine</div>
        <div class="w-100"></div>
        <div class="description value"
          [ngClass]="altStyle === 1 ? 'blue' : altStyle === 2 ? 'purple' : altStyle === 3 ? 'green' : altStyle === 4 ? 'purple-large-text' : 'extended-vehicle-details-page'">
          {{ engineFormat }}
        </div>
      </div>
    </div>
    <!-- vehicle details mileage and engine -->
  </div>

  <div class="d-flex flex-row flex-wrap-mobile" *ngIf="vehicleDetails">
    <div class="" style="width: 100%">
      <div class="row description-row">
        <div class="description header">Vcheck</div>
        <div class="w-100"></div>
        <div class="description value" [ngClass]="altStyle === 1 ? 'blue' : altStyle === 2 ? 'purple' : altStyle === 3 ? 'green' : altStyle === 4 ? 'purple-large-text' : ''">
          <img [src]="booleanIcon(vehicleDetails.hasSalvage)" alt="" class="ticks-style">
        </div>
      </div>
    </div>

    <div class="vertical-line" style="width: 100%">
      <div class="row description-row">
        <div class="description header">First Registered Date</div>
        <div class="w-100"></div>
        <div class="description value" [ngClass]="altStyle === 1 ? 'blue' : altStyle === 2 ? 'purple' : altStyle === 3 ? 'green' : altStyle === 4 ? 'purple-large-text' : ''">
          {{  vehicleDetails.registrationDate | date: 'dd MMMM yyyy'}}
        </div>
      </div>
    </div>
  </div>

  

<div *ngIf="!vehicleDetails" style="width: 100%">
  <div class="row description-row">
    <div class="description header">CAP ID</div>
    <div class="w-100"></div>
    <div class="description value salvage">{{ vehicleDescription.CapCodeId }}</div>
  </div>
</div>

<div class="vehicle-detail-container" [ngClass]="{ 'center  wide': vehicleDetails }">
  <div *ngIf="!hideDetails" class="d-flex flex-row flex-wrap-mobile ">
    <div *ngIf="vehicleDetails" style="width: 100%">
      <div class="row description-row">
      </div>
    </div>
    <div *ngIf="vehicleDetails" style="width: 100%">
      <div class="row description-row">
      </div>
    </div>
  </div>
</div>