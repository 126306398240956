import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, Subject } from 'rxjs';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';
import { AuthorizationRequestService } from '../services/authorization-request.service';
import { NotificationService } from '../services/notification.service';
import { AuthorizationStatus } from './enums/authorization-status';
import { AuthorizationRequest } from './models/authorization-request.model';
import { SignalREventService } from '../services/signal-revent.service';

@Component({
  selector: 'app-authorization-request',
  templateUrl: './authorization-request.component.html',
  styleUrls: ['./authorization-request.component.scss']
})
export class AuthorizationRequestComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() authorizationRequests: Array<AuthorizationRequest>;

  @Output() result: EventEmitter<AuthorizationRequest> = new EventEmitter();

  status: AuthorizationStatus;
  lastRefresh: Date;
  canRefresh = false;
  canClose = false;
  hasNewRequests = false;
  isEngineCheck = false;
  enableRetakeButton = false;
  hasComments = false;
  destroy$ = new Subject();

  getStatus = AuthorizationRequestComponent.getStatusFromRequest;

  static getStatusFromRequest(authorizationRequest: AuthorizationRequest): AuthorizationStatus {
    if (authorizationRequest.approved) {
      return AuthorizationStatus.Approved;
    } else if (authorizationRequest.declined) {
      return AuthorizationStatus.Declined;
    } else {
      return AuthorizationStatus.AwaitingApproval;
    }
  }

  constructor(
    private activeModal: NgbActiveModal,
    private authorizationRequestService: AuthorizationRequestService,
    private notifications: NotificationService,
    private signalREventService: SignalREventService
    ) {
  }

  ngOnInit(): void {
    this.hasNewRequests = this.authorizationRequests.some(a => a.isNew);
    this.checkForComments();
    this.enableRetake();
    if (this.anyRequestsPending) {
      this.canRefresh = true;
    }
    if (this.allRequestsAuthorized) {
      this.canClose = true;
    }
  }

  ngAfterViewInit(): void {
    this.signalREventService.authorizationRequestReceived$.pipe(takeUntil(this.destroy$))
      .subscribe((authorizationRequestNotification) => {
        if (!this.canClose) {
          const authRequest = this.authorizationRequests.find(x => x.authorizationRequestId === authorizationRequestNotification.authorizationRequestId);
          const index = this.authorizationRequests.findIndex(x=>x.authorizationRequestId === authorizationRequestNotification.authorizationRequestId);
          if(authRequest){
            this.authorizationRequestService.getRequest$(authorizationRequestNotification.authorizationRequestId).pipe(
              tap((result) => {
                if (result) {
                    this.authorizationRequests[index] = result;
                    if (authorizationRequestNotification.isAuthorized !== null) {
                      if (authorizationRequestNotification.isAuthorized) {
                        authRequest.approved = new Date();
                      } else {
                        authRequest.declined = new Date();
                      }
                    }
                    authRequest.isNew = false;
                    if (this.allRequestsAuthorized) {
                      this.canClose = true;
                      this.canRefresh = false;
                    }
                    this.enableRetake();
                    this.checkForComments();
                }
              }),
              catchError((err) => {
                this.notifications.dangerToast('Failed to get authorization request by id', err);
                return EMPTY;
              }),
              take(1)
            ).subscribe();
          }

          if (authRequest) {
            
          }
        }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  async refresh() {
    this.canRefresh = false;
    this.lastRefresh = new Date();

    const pendingRequestIds = this.authorizationRequests
      .filter(a => !a.approved && !a.declined)
      .map(a => a.authorizationRequestId);

    this.authorizationRequestService.getRequests$(pendingRequestIds)
      .pipe(
        tap((result) => {
          for (const authResponse of result) {
            const authorizationRequest = this.authorizationRequests.find(a => a.authorizationRequestId === authResponse.authorizationRequestId);

            authorizationRequest.approved = authResponse.approved;
            authorizationRequest.declined = authResponse.declined;
            authorizationRequest.authorizedBy = authResponse.authorizedBy;
            authorizationRequest.declinedReason = authResponse.declinedReason;
            if (authorizationRequest.approved || authorizationRequest.declined) {
              authorizationRequest.isNew = false;
            }
            this.enableRetake();
            this.checkForComments();
          }
          if (this.allRequestsAuthorized) {
            this.canClose = true;
          } else {
            setTimeout(() => {
              if (!this.allRequestsAuthorized) {
                this.canRefresh = true;
              }
            }, 5000);
          }
        }),
        catchError((err) => {
          this.notifications.dangerToast('Failed to get authorization request statuses', err);
          setTimeout(() => { this.canRefresh = true; }, 5000);
          return EMPTY;
        }),
        take(1)
      ).subscribe();
  }

  close(): void {
    this.authorizationRequests.forEach(element => {
      element.retake = false;
    });  
    this.activeModal.close(this.authorizationRequests);
  }

  checkForComments() {
    this.hasComments = this.authorizationRequests.some(a => a.declinedReason);
  }

  getStatusClass(authorizationRequest: AuthorizationRequest): string {
    const status = AuthorizationRequestComponent.getStatusFromRequest(authorizationRequest);
    switch (status) {
      case AuthorizationStatus.AwaitingApproval: return 'awaiting';
      case AuthorizationStatus.Approved: return 'approved';
      case AuthorizationStatus.Declined: return 'declined';
      default: return '';
    }
  }

  getAuthorizationTypeDescription(authorizationTypeId) {
    return AuthorizationRequestService.getAuthorizationTypeDescription(authorizationTypeId);
  }

  get allRequestsAuthorized(): boolean {
    return this.authorizationRequests.every(a => a.approved || a.declined);
  }

  get anyRequestsPending(): boolean {
    return this.authorizationRequests.some(a => !a.approved && !a.declined);
  }

  enableRetake() {
    this.enableRetakeButton = this.authorizationRequests.some(a => a.authorizationTypeId === 36 && a.declined !== null);
  }

  retakeVideo() {
    this.authorizationRequests.forEach(element => {
      element.retake = true;
    });
    this.activeModal.close(this.authorizationRequests);
  }
}
