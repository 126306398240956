<div class="question-label">{{ label }}</div>
<div class="wrapper">
    <div>
        <div class="image-input obfuscated-playback" [ngClass]="{ 'no-image': !isTaken }">
            <div [hidden]="isTaken" (click)="openCamera()" class="document-name">{{ video.documentName }}</div>
            <video [hidden]="!isTaken" [src]="video.thumbnailUrl" [ngClass]="'thumbnail'" (click)="showPreview(preview)"></video>
            <span [hidden]="!isTaken" class="delete-icon thumbnail-delete" (click)="deleteVideo()"></span>
        </div>
        <button class="camera-button" (click)="openCamera()"
            [ngClass]="{ 'loading': isLoading, 'active': !isTaken, 'isTaken': isTaken, 'inProgress': inProgress }"
            [disabled]="isTaken">
            <span [hidden]="isLoading" class="camera-btn-icon"
                [ngClass]="{ 'isTaken': isTaken, 'inProgress': inProgress }"></span>
        </button>
    </div>
</div>
<div *ngIf="showWarning" class='warning-label'>{{ warningMessage }}</div>

<input #cameraInput hidden type="file" capture="camcorder" accept="video/*" id="cameraInput" name="cameraInput"
    (change)="onFileSelected($event)" (cancel)="cancelFileUpload()" onclick="value = null">

<div class="progress progress-bar-margin" [hidden]="!showProgressBar || isEngineCheck">
    <div class="progress-bar bg-success"  [style.width.%]="currentUploadProgress" role="progressbar"></div>
</div>

<ng-template #preview let-modal>
    <div class="modal-header">
        <h4 class="modal-title text-center">{{ video.documentName }}</h4>
    </div>
    <div class="modal-body obfuscated-playback">
        <video [src]="video.thumbnailUrl" width="100%" playsinline="false" controls controlslist="nodownload" disablepictureinpicture="true"></video>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" (click)="modal.close()" ngbAutofocus>Close</button>
    </div>
</ng-template>
