import { EventEmitter, Injectable } from '@angular/core';
import { AuthTask } from '../task-list/models/auth-task.model';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  public valueChanged = new EventEmitter<boolean>();
  public videoChanged = new EventEmitter<boolean>();
  public videoSent = new EventEmitter<boolean>();
  public isSendingVideo = new EventEmitter<boolean>();
  public uploadSucceded = new EventEmitter<boolean>();
  public attemptsExceeded = new EventEmitter();
  public closeComponent = new EventEmitter();
  public declineTask = new EventEmitter<AuthTask>();
  public closeModal = new EventEmitter();
  public currentUploadProgress = new EventEmitter<number>();
}
