import { Component, OnInit } from '@angular/core';
import { PurchaseSummary } from '../models/purchase-summary.model';
import { PurchaseService } from '../../services/purchase.service';
import { NotificationService } from '../../services/notification.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, EMPTY } from 'rxjs';
import { catchError, tap, takeUntil } from 'rxjs/operators';
import { BaseComponentDirective } from '../../base/base.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VehicleDetails } from '../../models/vehicle-details';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ImageService } from '../../services/image.service';
import { ImageTypes } from '../../enums/image-types';
import { NavigationHistoryService } from '../../core/navigation-history.service';
import { AuthorizationRequestService } from '../../services/authorization-request.service';
import { AuthorizationRequest } from '../../authorization/models/authorization-request.model';

@Component({
  selector: 'app-purchase-summary',
  templateUrl: './purchase-summary.component.html',
  styleUrls: ['./purchase-summary.component.scss']
})
export class PurchaseSummaryComponent
  extends BaseComponentDirective
  implements OnInit {
  quoteStateId: number;
  purchaseSummary$: Observable<PurchaseSummary>;
  previewString: SafeUrl;
  previewStamp: string;
  vehicleDetails: VehicleDetails;
  mileage: number;
  mileageDecision: string;
  loadingVideo = false;
  authRequests: Array<AuthorizationRequest>;

  loading = true;

  purchaseSummary: PurchaseSummary;

  constructor(
    private purchaseService: PurchaseService,
    private notifications: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private imageService: ImageService,
    private navigationHistory: NavigationHistoryService,
    private authRequestsService: AuthorizationRequestService
  ) {
    super();
  } 

  ngOnInit() {
    this.route.params
      .pipe(
        tap((params) => {
          this.quoteStateId = params.id;
          this.purchaseSummary$ = this.purchaseService
            .getPurchaseDetails$(this.quoteStateId)
            .pipe(
              tap((result) => {

                this.purchaseSummary = result;

                result.documents.forEach(doc => {
                  if (doc.imgAsBase64String) {
                    doc.imgAsBase64String = this.sanitizer.bypassSecurityTrustUrl(doc.imgAsBase64String.toString());
                  }
                });

                result.answers.forEach(ans => {
                  if (ans.parameterName === 'PaymentDueDate') {
                    const payDate = new Date(ans.displayValue);
                    ans.displayValue = payDate.toLocaleString('en-GB');
                  }
                });

                result.vehicleImages.forEach(vi => {
                  if (vi.imgAsBase64String) {
                    vi.imgAsBase64String = this.sanitizer.bypassSecurityTrustUrl(vi.imgAsBase64String.toString());
                  }
                });
                this.vehicleDetails = new VehicleDetails(
                  result.valuation.manufacturer,
                  result.valuation.colour,
                  result.valuation.model,
                  result.valuation.engineSize,
                  result.valuation.derivative,
                  result.valuation.bookId,
                  result.valuation.capId,
                  result.valuation.imageUrl,
                  result.valuation.fuelType,
                  result.valuation.hasSalvage,
                  result.valuation.registrationDate,
                );
                this.mileage = parseInt(result.answers.find(item => item.parameterName === 'Mileage').value, 10);
                const mileageDecisionAnswer = result.answers.find(item => item.parameterName === 'MileageDecision');
                if (mileageDecisionAnswer) {
                  this.mileageDecision = mileageDecisionAnswer.value;
                }

                this.loading = false;
              }),
              catchError((err) => {
                this.notifications.dangerToast(
                  'Failed to retrieve purchase',
                  err
                );
                return EMPTY;
              })
            );
            this.getAuthRequests();
        }),
        takeUntil(this.componentDestroyed)
      )
      .subscribe();
  }

  getAuthRequests() {
    this.authRequestsService.getRequestByQuoteStateId$(this.quoteStateId).pipe(
      catchError((err) => {
        this.notifications.dangerToast(
          'An error occurred while trying to get auth request by quoteStateId.',
          err
        );
        return EMPTY;
      }),
      takeUntil(this.componentDestroyed)
    )
    .subscribe((requests) => {
      this.authRequests = requests;
    });
  }

  getAuthorizationTypeDescription(authorizationTypeId) {
    return AuthorizationRequestService.getAuthorizationTypeDescription(authorizationTypeId);
  }

  showPreview(photo, stamp, content) {
    if (photo.vehicleImageId) {
      this.imageService
        .getImagePreview$(photo.vehicleImageId, ImageTypes.VehiclePhoto)
        .pipe(
          catchError((err) => {
            this.notifications.dangerToast(
              'An error occurred while trying to get existing images.',
              err
            );
            return EMPTY;
          }),
          takeUntil(this.componentDestroyed)
        )
        .subscribe((result) => {
          this.previewString =
            this.sanitizer.bypassSecurityTrustUrl(
              result.imgAsBase64String
            );
          this.previewStamp = result.description;
          this.modalService.open(content, { size: 'lg' });
        });
    } else if (photo.documentScanId) {
      this.imageService
        .getImagePreview$(photo.documentScanId, ImageTypes.ScannedDocument)
        .pipe(
          catchError((err) => {
            this.notifications.dangerToast(
              'An error occurred while trying to get existing images.',
              err
            );
            return EMPTY;
          }),
          takeUntil(this.componentDestroyed)
        )
        .subscribe((result) => {
          this.previewString = this.sanitizer.bypassSecurityTrustUrl(
            result.imgAsBase64String
          );
          this.previewStamp = result.documentName;
          this.modalService.open(content, { size: 'lg' });
        });
    } else {
      this.previewString = photo.imgAsBase64String;
      this.previewStamp = stamp;
      this.modalService.open(content, { size: 'lg' });
    }
  }

  isPdf(file: string) {
    return file?.toLowerCase().endsWith('.pdf');
  }

  editVehicleImages() {
    this.navigationHistory.setId(null);
    this.router.navigate(['/postPurchase/images', this.quoteStateId], { queryParams: { imageType: ImageTypes.VehiclePhoto, isFromPurchaseMenu: true } });
  }

  editDocuments() {
    this.navigationHistory.setId(null);
    this.router.navigate(['/postPurchase/images', this.quoteStateId], { queryParams: { imageType: ImageTypes.ScannedDocument, isFromPurchaseMenu: true } });
  }

  showVideo(content, videoType) {
    this.loadingVideo = true;
    this.imageService.getVideo$(this.quoteStateId, videoType).pipe(
      catchError((err) => {
        this.loadingVideo = false;
        this.notifications.dangerToast(
          'An error occurred while trying to get video.',
          err
        );
        return EMPTY;
      }),
      takeUntil(this.componentDestroyed)
    )
      .subscribe((result) => {
        this.previewString = this.sanitizer.bypassSecurityTrustUrl(result.url);
        this.previewStamp = videoType;
        this.modalService.open(content, { size: 'lg' });
        this.loadingVideo = false;
      });
  }
}
