import { Component, Input, OnInit } from '@angular/core';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { Appointment } from '../../appointment-details/models/appointment.model';
import { AuthService } from '../../core/auth-service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { PurchaseService } from '../../services/purchase.service';
import { VehicleDetails } from '../../models/vehicle-details';
import { NotificationService } from '../../services/notification.service';
import { AuthTask } from '../models/auth-task.model';
import { ConfirmDialogService } from '../../confirm-dialog/services/confirm-dialog.service';
import { AuthorizationRequestService } from '../../services/authorization-request.service';
import { ImageService } from '../../services/image.service';
import { Router } from '@angular/router';
import { ImageTypes } from '../../enums/image-types';
import { BaseComponentDirective } from '../../base/base.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AnswerValue } from '../../purchase/models/answer-value.model';
import { TaskListAuthComponent } from '../task-list-auth/task-list-auth.component';
import { FlexAuth } from '../../appointment-details-auth/models/flex-auth.model';
import { PathFinderService } from '../../services/path-finder.service';
import { AuthorizationTypes } from '../../authorization/enums/authorization-types';
import { TaskCounterService } from '../../services/task-counter.service';
import { CounterTypes } from '../../enums/counter-types';
import { DeclineReasonComponent } from '../../appointment-details-auth/decline-reason/decline-reason.component';
import { EventsService } from '../../services/events.service';

@Component({
  selector: 'app-auth-task-details',
  templateUrl: './auth-task-details.component.html',
  styleUrls: ['./auth-task-details.component.scss']
})
export class AuthTaskDetailsComponent extends BaseComponentDirective implements OnInit {

  @Input() appointmentId: number;

  constructor(
    private notifications: NotificationService,
    private authService: AuthService,
    private router: Router,
    private modalService: NgbModal,
    private confirmService: ConfirmDialogService,
    private imageService: ImageService,
    private authRequestService: AuthorizationRequestService,
    private purchaseService: PurchaseService,
    private taskListAuth: TaskListAuthComponent,
    private sanitizer: DomSanitizer,
    private pathFinder: PathFinderService,
    private counterService: TaskCounterService,
    private eventsService: EventsService) {
      super(); 
    }

    @Input() vehicleDetails: VehicleDetails;
    @Input() mileage: number;
    @Input() buyerName: string;
    @Input() authTask: AuthTask;
    @Input() buyerExpLevel: number;

  appointment: Appointment;
  username: string;
  quoteStateId: number;
  mileageDecision: string;
  previewString: SafeUrl;
  previewStamp: string;
  modalData$: Observable<Appointment>;
  allAnswers: Array<AnswerValue>;

  ApproveText = 'Are you sure you want to\napprove this authorisation?';
  DeclineText = 'Are you sure you want to\ndecline this authorisation?';

  hasAuthDetailsTab = false;
  defaultVehicleImage = '../../assets/images/unknownCar.png';
  hpiVehicleDetails: VehicleDetails;
  flexAuth: FlexAuth;
  suggestedWriteOffDesc: string;
  chosenWriteOffDesc: string;
  declineSubscription: Subscription;

  ngOnInit(): void {
    this.appointment = new Appointment();
    this.username = this.authService.getUser()?.name;
    this.setHasAuthDetailsTab();
    this.modalData$ = this.purchaseService
      .getAuthTaskAppointmentDetail$(this.appointmentId, this.authTask.authorizationTypeId)
      .pipe(
        tap((result) => {
          this.allAnswers = result.answers;
          this.quoteStateId = result.quoteStateId;
          result.documents.forEach(doc => {
            if (doc.imgAsBase64String) {
              doc.imgAsBase64String = this.sanitizer.bypassSecurityTrustUrl(doc.imgAsBase64String.toString());
            }
          });

          result.vehicleImages.forEach(vi => {
            if (vi.imgAsBase64String) {
              vi.imgAsBase64String = this.sanitizer.bypassSecurityTrustUrl(vi.imgAsBase64String.toString());
            }
          });
          this.vehicleDetails = new VehicleDetails(
            result.valuation.manufacturer,
            result.valuation.colour,
            result.valuation.model,
            result.valuation.engineSize,
            result.valuation.derivative,
            result.valuation.bookId,
            result.valuation.capId,
            result.valuation.imageUrl,
            result.valuation.fuelType,
            result.valuation.hasSalvage,
            result.registrationDate
          );
          this.mileage = parseInt(result.answers.find(item => item.parameterName === 'Mileage').value, 10);
          const mileageDecisionAnswer = result.answers.find(item => item.parameterName === 'MileageDecision');
          if (mileageDecisionAnswer) {
            this.mileageDecision = mileageDecisionAnswer.displayValue;
          }

          this.hpiVehicleDetails = new VehicleDetails(
            result.previousMake,
            result.previousColour,
            result.previousModel,
            result.previousDerivative,
            result.previousEngineSize,
            result.previousBookId,
            result.previousCapId,
            result.previousImageUrl,
            result.previousFuelType,
            result.valuation.hasSalvage,
            result.previousRegistrationDate
          );
          this.flexAuth = new FlexAuth(result.vehiclePriceOfferedBeforeFlex - result.valuation.adminFee,
            result.valuation.customerPaymentAmount,
            result.valuation.totalDamageCost,
            result.valuation.totalFlexAmount);
          if (this.isInsuranceWriteOff) {
            const descs = this.pathFinder.GetInsuranceWriteOffDescription(result.suggestedWriteOffOption, result.chosenWriteOffOption);
            this.suggestedWriteOffDesc = descs[0];
            this.chosenWriteOffDesc = descs[1];
          }
        }),
        catchError((err) => {
          this.notifications.dangerToast(
            'Failed to retrieve purchase',
            err
          );
          return EMPTY;
        })
      );

      this.declineSubscription = this.eventsService.declineTask.subscribe((response: AuthTask) => {
        this.declineAuthTask(response);
      });
  }

  showDeclineModal(task: AuthTask){
    const declineReasonModal = this.modalService.open(DeclineReasonComponent, {
      keyboard: true,
      backdrop: 'static',
      centered: true,
      size: 'lg'
    });

    declineReasonModal.componentInstance.authTask = task;
  }

  approveAuthTask(task: AuthTask) {
    this.confirmService.showYesNoConfirmation(this.ApproveText,
      () => {
        this.actuallyApproveAuthTask(task);
        this.unsubscribe()
      }, () => {
      });
  }

  declineAuthTask(task: AuthTask) {
    this.confirmService.showYesNoConfirmation(this.DeclineText,
      () => {
        this.actuallyDeclineAuthTask(task);
        this.unsubscribe()
      }, () => {
      });
  }

  actuallyApproveAuthTask(task: AuthTask) {
    task.approved = new Date();
    task.authorizedBy = this.buyerName;
    this.authRequestService.updateAuthTask(task);
    this.taskListAuth.backToTasks();
    this.taskListAuth.refreshTasks();
    this.counterService.update(CounterTypes.AUTHTASK);
  }

  actuallyDeclineAuthTask(task: AuthTask) {
    this.eventsService.closeModal.emit();
    task.declined = new Date();
    task.authorizedBy = this.buyerName;
    this.authRequestService.updateAuthTask(task);
    this.taskListAuth.backToTasks();
    this.taskListAuth.refreshTasks();
    this.counterService.update(CounterTypes.AUTHTASK);
  }

  checkPrivatePlate(privatePlate) {
    if (privatePlate.toLowerCase() === 'false') {
      return false;
    } else {
      return true;
    }
  }

  isPdf(file: string) {
    return file?.toLowerCase().endsWith('.pdf');
  }

  showPreview(photo, stamp, content) {
    if (photo.vehicleImageId) {
      this.imageService
        .getImagePreview$(photo.vehicleImageId, ImageTypes.VehiclePhoto)
        .pipe(
          catchError((err) => {
            this.notifications.dangerToast(
              'An error occurred while trying to get existing images.',
              err
            );
            return EMPTY;
          }),
          takeUntil(this.componentDestroyed)
        )
        .subscribe((result) => {
          this.previewString =
          this.sanitizer.bypassSecurityTrustUrl(
            result.imgAsBase64String
          );
          this.previewStamp = result.description;
          this.modalService.open(content, { size: 'sm' });
        });
    } else if (photo.documentScanId) {
      this.imageService
        .getImagePreview$(photo.documentScanId, ImageTypes.ScannedDocument)
        .pipe(
          catchError((err) => {
            this.notifications.dangerToast(
              'An error occurred while trying to get existing images.',
              err
            );
            return EMPTY;
          }),
          takeUntil(this.componentDestroyed)
        )
        .subscribe((result) => {
          this.previewString = this.sanitizer.bypassSecurityTrustUrl(
            result.imgAsBase64String
          );
          this.modalService.open(content, { size: 'sm' });
        });
    } else {
      this.previewString = photo.imgAsBase64String;
      this.previewStamp = stamp;
      this.modalService.open(content, { size: 'sm' });
    }
  }

  editVehicleImages() {
    this.router.navigate(['/postPurchase/images', this.quoteStateId]);
  }

  get isHpiMismatch() {
    return this.authTask.authorizationTypeId === AuthorizationTypes.HpiMismatch;
  }

  get isVehicleDetailsChanged() {
    return this.authTask.authorizationTypeId === AuthorizationTypes.VehicleDetailsChanged;
  }

  get isInsuranceWriteOff() {
    return this.authTask.authorizationTypeId === AuthorizationTypes.InsuranceWriteOffOverride;
  }

  get isFlex() {
    return this.authTask.authorizationTypeId === AuthorizationTypes.FlexBand1 ||
      this.authTask.authorizationTypeId === AuthorizationTypes.FlexBand2 ||
      this.authTask.authorizationTypeId === AuthorizationTypes.SuperFlex;
  }

  get authTypeWarningMessage() {
    switch (this.authTask.authorizationTypeId) {
      case AuthorizationTypes.HpiMismatch:
        return 'You are approving the HPI vehicle details to be overridden to the customer selected vehicle';
      case AuthorizationTypes.VehicleDetailsChanged:
        return 'You are approving the vehicle details to be changed to the Branch Managers selection on this purchase';
      case AuthorizationTypes.InsuranceWriteOffOverride:
        return 'You are approving the HPI insurance write off marker to be overridden to the Branch Managers selection';
      case AuthorizationTypes.FlexBand1:
        return 'Flex Band 1 authorisation';
      case AuthorizationTypes.FlexBand2:
        return 'Flex Band 2 authorisation';
      case AuthorizationTypes.SuperFlex:
        return 'Super Flex authorisation';
      default:
        return '';
    }
  }

  getOptionDescription(value: string): string {
    const parts = value.split(' - ');
    if (parts.length > 1) {
      return parts[1].replace(', ', ',<br>');
    } else {
      return parts[0].replace(', ', ',<br>');
    }
  }

  getOptionIcon(value: string): string {
    const parts = value.split(' ');
    const first = parts[0].replace('/', '-');
    return '../../assets/icons/write-off/write-off-' + first + '.png';
  }

  private setHasAuthDetailsTab() {
    switch (this.authTask.authorizationTypeId) {
      case AuthorizationTypes.HpiMismatch:
      case AuthorizationTypes.VehicleDetailsChanged:
      case AuthorizationTypes.InsuranceWriteOffOverride:
      case AuthorizationTypes.FlexBand1:
      case AuthorizationTypes.FlexBand2:
      case AuthorizationTypes.SuperFlex:
        this.hasAuthDetailsTab = true;
        break;
      default:
        this.hasAuthDetailsTab = false;
        break;
    }
  }

  unsubscribe() {
    this.declineSubscription.unsubscribe();
  }
}
