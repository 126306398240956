import { Component, Input, OnInit,AfterViewInit , ViewChild } from '@angular/core';
import { ImageToSave } from '../models/image-to-save.model';
import { ImageService } from '../../services/image.service';
import { EMPTY, catchError, takeUntil } from 'rxjs';
import { NotificationService } from '../../services/notification.service';
import { BaseComponentDirective } from '../../base/base.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-video-preview',
  templateUrl: './video-preview.component.html',
  styleUrl: './video-preview.component.scss'
})
export class VideoPreviewComponent extends BaseComponentDirective implements OnInit, AfterViewInit {
  @ViewChild('videoPlayer') videoPlayer;
  @Input() quoteStateId: number;
  video= new ImageToSave();
  previewString: SafeUrl;
  previewStamp: string;
  loading: boolean;
  videoUrl: string = '';

  constructor(
    private imageService: ImageService,
    private notifications: NotificationService,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal
  ) { super()
  } 

  ngOnInit(): void {
    this.loading = true;
  }

  ngAfterViewInit() {
    this.imageService.getVideoFileName$(this.quoteStateId,"EngineVideo").pipe(
      catchError((err) => {
        this.notifications.dangerToast(
          'An error occurred while trying to get video.',
          err
        );
        return EMPTY;
      }),
      takeUntil(this.componentDestroyed)
    )
      .subscribe((result) => {
        if(result){
          this.loadVideo(result);
        }else{
          this.loading = false;
        }
      });
  }

  loadVideo(filename: string): void{
    this.imageService.getVideoUrl(filename).subscribe(blob =>{
      this.videoUrl = URL.createObjectURL(blob);
      this.videoPlayer.nativeElement.src = this.videoUrl;
      this.loading = false;
    }, error => {
      console.log(error)
      this.loading = false;
    })
  }

  openVideo(content){
        this.modalService.open(content, { 
          backdrop: 'static',
          centered: true,
          size: 'lg'
      });
  }

}
