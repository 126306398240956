import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PathFinderService } from '../../services/path-finder.service';
import { DamageService } from '../../services/damage.service';
import { NotificationService } from '../../services/notification.service';
import { catchError, take, tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { Signature } from '../../invoice/models/signature.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SignatureInputComponent } from '../../signature-input/signature-input.component';
import { LoadingService } from '../../services/loading.service';
import { Fault } from '../models/fault.model';

@Component({
  selector: 'app-appraisal-report',
  templateUrl: './appraisal-report.component.html',
  styleUrls: ['./appraisal-report.component.scss']
})
export class AppraisalReportComponent implements OnInit {

  @Input() quoteStateId: number;
  @Input() group: UntypedFormGroup;
  @Input() set canLoadContent(canLoad: boolean) {
    this.isDataReady = canLoad;
    this.retrieveAppraisalData();
  }

  loading: boolean;
  isDataReady: boolean;
  isInitialised: boolean;
  reportContent: string;
  damageItems: Array<Fault>;
  customerSignature: Signature;
  customerContent: string;
  customerDamageItems: Array<Fault>;
  source: string;
  hasDamages: boolean;

  constructor(private pathFinder: PathFinderService, private damageService: DamageService,
    private notifications: NotificationService, private modalService: NgbModal,  private loadingService: LoadingService) { }

  ngOnInit(): void {
    this.loading = true;
    this.loadingService.loading.emit(this.loading);
    this.isInitialised = true;
    this.retrieveAppraisalData();
    setTimeout(() => {
      this.group.controls.value.setValue(null);
    });
  }

  retrieveAppraisalData() {
    if (this.isInitialised && (this.isDataReady || this.isDataReady === undefined)) {
      this.damageService.getAppraisalReportContent$(this.quoteStateId).pipe(
        tap(result => {
          this.reportContent = result.reportHtml;
          this.damageItems = result.damageItems;
          this.customerContent = result.customerReportHtml;
          this.customerDamageItems = result.customerDamageItems;
          this.hasDamages = result.customerDamageItems.length > 0;
          this.source = result.source;
          this.loading = false;
          this.loadingService.loading.emit(this.loading);
        }),
        catchError(err => {
          this.loading = false;
          this.loadingService.loading.emit(this.loading);
          this.notifications.dangerToast('Failed to retrieve appraisal report', err);
          return EMPTY;
        }),
        take(1)
      ).subscribe();
    }
  }

  sign() {
    const modalRef = this.modalService.open(SignatureInputComponent, {
      keyboard: false,
      backdrop: 'static',
      centered: true,
      size: 'xl',
      windowClass: 'signatureWindow'
    });
    modalRef.componentInstance.isWbacSignature = false;
    modalRef.componentInstance.quoteStateId = this.quoteStateId;
    modalRef.componentInstance.disclaimerPrefix = 'Vendor:';
    modalRef.componentInstance.disclaimer = 'I confirm this is an accurate description of my vehicle';
    modalRef.result.then(result => {
      this.customerSignature = result;
      this.group.controls.value.setValue(this.damageItems.length > 0);
      this.loadingService.loading.emit(true);
      this.damageService.generateAppraisalReport$(this.quoteStateId, this.customerSignature).pipe(
        tap(() => {
          this.loadingService.loading.emit(false);
        }),
        catchError(err => {
          this.notifications.dangerToast('Failed to generate appraisal report', err);
          this.loadingService.loading.emit(false);
          return EMPTY;
        }),
        take(1)
      ).subscribe();
    }, () => { });
  }
}
